.against-map-wrapper {
	flex: 1;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	padding: 90px 60px;
	.loading-txt {
		font-size: 18px;
		color: #ffffff;
		text-align: center;
		margin-bottom: 60px;
	}
	.againstor {
		display: flex;
		justify-content: space-between;
		position: relative;
		margin-bottom: 80px;
		> .vs {
			position: absolute;
			left: 50%;
			top: 50%;
			-webkit-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			background-size: cover;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/vs.png");
			width: 70px;
			height: 44px;
		}
		> .user {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			> .img {
				height: 60px;
				width: 60px;
				background-color: #ffe5e5;
				border-radius: 50%;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				background-size: cover;
			}
			> span {
				width: 60px;
				margin-top: 10px;
				color: #ffffff;
				font-size: 16px;
				text-align: center;
				word-break: break-all;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
			> .status {
				position: absolute;
				right: 10px;
				bottom: 36px;
				background-size: cover;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				-webkit-transform: translate(50%, 50%);
				transform: translate(50%, 50%);
				&.wait {
					width: 24px;
					height: 24px;
					background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/status-wait.png");
				}
				&.start {
					background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/status-start.png");
					width: 36px;
					height: 24px;
				}
			}
		}
	}
	.against-btn {
		height: 50px;
		line-height: 50px;
		color: #ffffff;
		text-align: center;
		margin-bottom: 24px;
		border-radius: 8px;
		text-decoration: none;
		font-size: 16px;
		&:last-child {
			margin-bottom: 0;
		}
		&.wait {
			background-color: #956251;
		}
		&.start {
			background-image: linear-gradient(#ff9532, #ff4b09);
		}
		&.leave {
			background-image: linear-gradient(#fb9d8d, #e56971);
		}
	}
}
.leave-tip-wrap{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 99;
	background-color: rgba(0,0,0,0.75);
	display: flex;
	align-items: center;
	justify-content: center;
	>.leave-tip{
		background-color: #ffffff;
		border-radius: 12px;
		color: #333333;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 16px 20px;
		width: 65%;
		line-height: 1.8;
		flex-direction: column;
		>img{
			width: 40px;
			height: 40px;
			margin-bottom: 28px;
		}
		>span{
			>.sec{
				color: #ff4812;
				font-size: 18px;
				padding: 0 4px;
				font-style: normal;
				font-weight: 700;
			}
		}
	}
}
