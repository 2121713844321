.order-list-wrapper {
	flex: 1;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	position: relative;
	background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/against-bottom-bg.png");
	background-repeat: no-repeat;
	background-position: 50% 100%;
	background-size: 100%;
	padding: 20px 20px 44px;
	> .list-wrapper {
		background-color: #ffffff;
		flex: 1;
		display: flex;
		flex-direction: column;
		.tabs {
			height: 44px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-bottom: solid 1px #dcdcdc;
			> span {
				flex: 1;
				height: 44px;
				line-height: 44px;
				text-align: center;
				position: relative;
				&.active {
					color: #fbca25;
					&:after {
						position: absolute;
						content: '';
						background-color: #fbca25;
						height: 4px;
						width: 100%;
						bottom: 0;
						left: 0;
					}
				}
			}
		}
		> .list {
			flex: 1;
			overflow: auto;
			> .item {
				font-size: 12px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 66px;
				padding: 0 15px;
				border-bottom: solid 1px #dcdcdc;
				&:last-child {
					border: none;
				}
				&.first {
					.info {
						.num {
							height: 18px;
							background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/order1.png");
							background-size: 100%;
							background-repeat: no-repeat;
							background-color: transparent;
							background-position: 50% 100%;
						}
						.name {
							color: #ff0000;
							font-size: 16px;
						}
					}
				}
				&.second {
					.info {
						.num {
							height: 18px;
							background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/order2.png");
							background-size: 100%;
							background-repeat: no-repeat;
							background-color: transparent;
							background-position: 50% 100%;
						}
						.name {
							color: #ff6300;
							font-size: 16px;
						}
					}
				}
				&.third {
					.info {
						.num {
							height: 18px;
							background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/order3.png");
							background-size: 100%;
							background-repeat: no-repeat;
							background-color: transparent;
							background-position: 50% 100%;
						}
						.name {
							color: #fd8900;
							font-size: 16px;
						}
					}
				}
				.info {
					display: flex;
					align-items: center;
					.num {
						height: 15px;
						line-height: 15px;
						width: 15px;
						background-color: #fab000;
						color: #ffffff;
						text-align: center;
						border-radius: 50%;
						margin-right: 15px;
						font-size: 11px;
					}
					.avatar {
						width: 46px;
						height: 46px;
						border-radius: 50%;
						background-color: rgba(0, 0, 0, 0.25);
						margin-right: 15px;
						background-size: cover;
						background-position: 50% 50%;
						background-repeat: no-repeat;
					}
					.name {
						color: #333333;
					}
				}
				.point {
					color: #999999;
					> span {
						color: #fcd759;
						margin-left: 15px;
						display: inline-block;
						width: 32px;
					}
				}
			}
		}
	}
}
