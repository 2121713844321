.topic-list-wrapper {
	box-sizing: border-box;
	padding: 10px;
	.list-item-wrapper {
		flex: 1;
		display: flex;
		margin: 0 10px 10px;
		position: relative;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.list-item {
		float: left;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-decoration: none;
		margin-right: 10px;
		box-sizing: border-box;
		width: calc(~"50% - 5px");
		height: 108px;
		margin-bottom: 10px;
		&:nth-child(2n){
			margin-right: 0;
		}
		> .img {
			flex: 1;
			background-size: cover;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			border-radius: 8px;
			overflow: hidden;
			position: relative;
			width: 100%;
		}
		> span {
			margin-top: 2px;
			color: #ffffff;
			font-size: 13px;
			text-align: center;
			line-height: 1.5;
		}
	}
	.list-item-cover {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.65);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		>.lock {
			background-repeat: no-repeat;
			background-size: auto 100%;
			background-position: 50% 35%;
			background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/lock.png");
			width: 24px;
			height: 24px;
		}
		>.open-time{
			margin-top: 6px;
			text-align: center;
			font-size: 12px;
			color: #ffffff;
		}
	}
}
