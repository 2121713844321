.history-wrapper {
	flex: 1;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	background-color: #ffffff;
	.header{
		height: 128px;
		display: flex;
		flex-direction: column;
		padding: 32px 20px 0;
		box-sizing: border-box;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;
		>span{
			color: #ffffff;
			font-size: 12px;
			&.yan{
				font-weight: 700;
				font-size: 15px;
				margin-bottom: 8px;
			}
		}
	}
	.calendar{
		flex: 1;
		overflow: auto;
		box-shadow: 0 0 10px rgba(0,0,0,0.15);
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		.calendar-item{
			background-color: #ffffff;
			margin: 16px;
			box-shadow: 0 0 10px rgba(0,0,0,0.15);
			border-radius: 8px;
			overflow: hidden;
			padding-bottom: 10px;
		}
		.calendar-header{
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			box-shadow: 0 2px 2px rgba(0,0,0,0.05);
			font-weight: 800;
		}
		.weeks{
			color: #444444;
			height: 36px;
			line-height: 36px;
			display: block;
			align-items: center;
			overflow: hidden;
			>span{
				display: block;
				float: left;
				width: calc(~"100%/7");
				text-align: center;
			}
		}
		.days{
			color: #929292;
			display: block;
			align-items: center;
			overflow: hidden;
			>.day-item{
				height: 32px;
				line-height: 32px;
				float: left;
				width: calc(~"100%/7");
				text-align: center;
			}
		}
	}
}