.ypdj-home-wrapper-cover {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
}

.ypdj-home-wrapper {
	flex: 1;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	background-color: #FDF0D3;
	background-repeat: no-repeat;
	background-size: 100%;
	background-image: url("../common/images/ypdj/home-bg.jpg");
	padding-bottom: 28px;
	> .userinfo {
		display: flex;
		justify-content: space-between;
		//flex: 1;
		height: 102px;
		align-items: center;
		color: #ffffff;
		padding: 10px 20px;
		box-sizing: border-box;
		> .avatar {
			display: flex;
			height: 62px;
			align-items: center;
			> .img {
				background-size: cover;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				width: 62px;
				height: 62px;
				border-radius: 50%;
			}
			> span {
				margin-left: 10px;
				font-size: 16px;
				max-width: 56px;
				line-height: 18px;
				overflow:hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		}
		> .point {
			display: flex;
			align-items: flex-start;
			//height: 32px;
			>.point-value{
				display: flex;
				flex-direction: column;
				position: relative;
				align-items: flex-start;
				margin-right: 18px;
				&:last-child{
					margin-right: 0;
				}
				> span {
					display: block;
					padding-left: 8px;
					font-size: 12px;
					flex: 1;
					text-align: left;
					height: 12px;
					line-height: 12px;
					margin-bottom: 4px;
					position: relative;
					&:before{
						position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
						width: 3px;
						height: 3px;
						border-radius: 50%;
						background-color: #ffffff;
						content: '';
					}
					&:last-child{
						margin-bottom: 0;
					}
					&.title{
						font-size: 14px;
						height: 14px;
						padding-left: 0px;
						font-weight: 800;
						line-height: 14px;
						margin-bottom: 12px;
						text-align: justify;
						&:before{
							display: none;
						}
					}
				}
			}
			>.img {
				background-size: auto 100%;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				width: 24px;
				height: 24px;
				background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/cert-active.png");
			}
		}
	}
	> .banner {
		padding-top: 46.66%;
		width: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		margin-bottom: 10px;
	}
	>.cwrap {
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: 100%;
		padding-top: 60%;
		margin-top: 40%;
		width: 100%;
		position: relative;
		>.wrapinner{
			display: flex;
			flex-direction: column;
			position: absolute;
			left:0;
			top: 0;
			width: 100%;
			height: 100%;
			align-items: center;
			//justify-content: center;
			box-sizing: border-box;
			padding-top: 13%;
			>.dakainfo{
				display: flex;
				justify-content: center;
				margin-top: 14px;
				&:last-child{
					margin-top: 6px;
				}
				>span{}
				>em{
					font-style: normal;
					color: #F1605A;
				}
			}
			>span{
				font-size: 20px;
				font-weight: 800;
				>em{
					font-size: 52px;
					font-style: normal;
					position: relative;
					bottom: -4px;
					padding-right: 6px;
				}
			}
		}
	}
	.daka-action{
		display: block;
		background-color: #F1534B;
		width: 75%;
		color: #ffffff;
		font-size: 16px;
		text-align: center;
		height: 48px;
		line-height: 48px;
		border-radius: 36px;
		margin-top: 14px;
		text-decoration: none;
	}
	.mydaka-action{
		text-decoration: underline;
		color: #444;
		margin-top: 16px;
		font-size: 16px;
	}
	.study-entrance{
		padding-top: 30.71%;
		width: 78%;
		background-size: cover;
		background-repeat: no-repeat;
		border-radius: 10px;
		overflow: hidden;
		margin: 10px auto;
		position: relative;
		>span{
			font-size: 18px;
			position: absolute;
			left: 10px;
			bottom: 20px;
			color: #ffffff;
			text-decoration: underline;
		}
	}
	> .entrance {
		padding: 0 10px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		position: relative;
		> .item {
			padding-top: 39.71%;
			width: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			margin-bottom: 10px;
			border-radius: 10px;
			overflow: hidden;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	> .entrance2 {
		margin-top: 10px;
		padding: 0 10px;
		box-sizing: border-box;
		display: flex;
		position: relative;
		width: 100%;
		//padding-top: 39.71%;
		> .item {
			width: calc(~"50% - 5px");
			padding-top: 36%;
			margin-right: 10px;
			//height: 100%;
			//flex: 1;
			background-size: cover;
			background-repeat: no-repeat;
			margin-bottom: 20px;
			border-radius: 10px;
			overflow: hidden;
			background-color: rgba(0, 0, 0, 0.45);
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.mask {
	position: fixed;
	left: 0;
	width: 100%;
	top: 0;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.45);
	z-index: 1000;
}

.register-wrapper {
	position: absolute;
	z-index: 1001;
	background-color: #ffffff;
	width: 74.66%;
	top: 50%;
	left: 50%;
	-webit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	> .title {
		font-size: 18px;
		text-align: center;
		height: 70px;
		line-height: 70px;
	}
	> .form-wrap {
		padding: 0 28px;
		box-sizing: border-box;
		> .form-item {
			background-color: #d9d9d9;
			box-sizing: border-box;
			height: 36px;
			margin-bottom: 14px;
			position: relative;
			&:last-child {
				margin-bottom: 0;
			}
			display: flex;
			> select {
				flex: 1;
				background: transparent;
				border: transparent;
				outline: none;
				padding: 0 10px;
				&::-webkit-input-placeholder {
					color: #7e7e7e;
				}
			}
			> input {
				flex: 1;
				background: transparent;
				border: transparent;
				outline: none;
				box-sizing: border-box;
				padding: 0 10px;
				&::-webkit-input-placeholder {
					color: #7e7e7e;
				}
			}
		}
	}
	> .formbtn-wrap {
		display: flex;
		margin: 50px 0;
		padding: 0 28px;
		> span {
			flex: 1;
			margin-right: 16px;
			display: block;
			height: 38px;
			line-height: 38px;
			text-align: center;
			color: #ffffff;
			&:last-child {
				margin-right: 0;
			}
			&.submit {
				background-color: #0da7ff;
			}
			&.cancel {
				background-color: #d9d9d9;
			}
		}
	}
}
