.cert-wrapper {
	flex: 1;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	background-size: cover;
	background-position: 50% 0;
	background-repeat: no-repeat;
	background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/cert-bg.jpg");
	position: relative;
	.cert-list {
		overflow: hidden;
		width: 90%;
		box-sizing: border-box;
		position: absolute;
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%, -44%);
		transform: translate(-50%, -44%);
		//margin-top: -20px;
		.cert-item {
			width: 25%;
			float: left;
			display: flex;
			flex-direction: column;
			box-sizing: border-box;
			align-items: center;
			margin-bottom:10px;
			> .cover {
				width: 100%;
				height: 32px;
				background-size: auto 100%;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/cert.png");
			}
			> span {
				width: 75%;
				display: block;
				color: #555;
				font-size: 10px;
				text-align: center;
				margin-top: 6px;
			}
			&.active {
				> .cover {
					background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/cert-active.png");
				}
				> span {
					color: #333333;
				}
			}
		}
	}
}
