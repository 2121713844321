.topic-answer-wrapper {
	flex: 1;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	padding: 20px 14px;
	background-color: #f1f1f1;
	.countdown-wrapper {
		background-color: #ffffff;
		border: solid 1px #dcdcdc;
		border-radius: 4px;
		display: flex;
		align-items: center;
		padding: 0 10px;
		height: 34px;
		box-sizing: border-box;
		> .countdown {
			overflow: hidden;
			height: 6px;
			background-color: #d6d6d6;
			border-radius: 4px;
			position: relative;
			flex: 1;
			margin-right: 20px;
			> em {
				position: absolute;
				left: 0;
				background-color: #3a92ff;
				top: 0;
				height: 100%;
			}
		}
		> span {
			font-size: 14px;
			color: #333333;
			width: 120px;
			text-align: center;
		}
	}
	.question-wrapper {
		background-color: #ffffff;
		border: solid 1px #dcdcdc;
		border-radius: 4px;
		padding: 0 10px;
		box-sizing: border-box;
		margin-top: 14px;
		.qheader {
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			> .title {
				width: 92px;
				height: 30px;
				background-size: cover;
				background-repeat: no-repeat;
				background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/question-title.png");
				display: flex;
				align-items: center;
				justify-content: center;
				color: #ffffff;
			}
			> .order {
				font-size: 14px;
				> i {
					font-style: normal;
					font-size: 21px;
					color: #993331;
				}
			}
		}
		.qcontent {
			font-size: 16px;
			color: #333333;
			.quesiton {
				margin-bottom: 32px;
				line-height: 1.5;
			}
			.answers {
				margin-bottom: 18px;
				> .item {
					background-color: #ffffff;
					border: solid 1px #dcdcdc;
					border-radius: 4px;
					display: flex;
					align-items: flex-start;
					padding: 10px 56px 10px 10px;
					margin-bottom: 14px;
					box-sizing: border-box;
					line-height: 1.5;
					> label {
						width: 20px;
					}
					> span {
						flex: 1;
					}
					&:last-child {
						margin-bottom: 0;
					}
					&.right {
						position: relative;
						&:after {
							position: absolute;
							right: 20px;
							top: 10px;
							content: '';
							width: 24px;
							height: 18px;
							background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/status-start.png");
							background-position: 100% 50%;
							background-size: 100%;
							background-repeat: no-repeat;
						}
					}
					&.wrong {
						position: relative;
						&:after {
							position: absolute;
							right: 20px;
							top: 10px;
							content: '';
							width: 18px;
							height: 18px;
							background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/status-wait.png");
							background-position: 100% 50%;
							background-size: 100%;
							background-repeat: no-repeat;
						}
					}
					&.selected {
						position: relative;
						background-color: rgba(161, 59, 54, 0.35);
					}
				}
			}
		}
		.qsubmit {
			border: solid 1px #3a92ff;
			background-color: #3a92ff;
			color: #ffffff;
			width: 100%;
			height: 38px;
			line-height: 38px;
			margin-bottom: 25px;
			text-align: center;
			border-radius: 20px;
			font-size: 16px;
			text-decoration: none;
			&.disabled{
				border: solid 1px #999999;
				background-color: #999999;
			}
		}
		.submit-ctrls{
			margin-bottom: 25px;
			font-size: 16px;
			display: flex;
			>.ctrl-item{
				border-radius: 20px;
				border: solid 1px #3a92ff;
				background-color: #3a92ff;
				color: #ffffff;
				height: 38px;
				line-height: 38px;
				flex: 1;
				margin-right: 20px;
				text-align: center;
				&:last-child{
					margin-right: 0;
				}
				&.disabled{
					border: solid 1px #999999;
					background-color: #999999;
				}
			}
		}
	}
}