* {
	padding: 0;
	margin: 0;
}

html, body {
	font-family: sans-serif;
	color: #333333;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-overflow-scrolling: touch;
	-webkit-tap-highlight-color: transparent;
	-webkit-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-webkit-font-smoothing: antialiased;
	font-size: 14px;
	height: 100%;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
	"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
	monospace;
}

.container {
	width: 100%;
	background-color: #f9f9f9;
	overflow: hidden;
}

.body-container {
	max-width: 1200px;
	margin: 0 auto;
}

input, select {
	outline: none;
}

#loading {
	background-image: url("./common/images/loading.svg");
	background-size: 28px 28px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-color: rgba(0, 0, 0, 0);
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 10000;
	display: none;
	animation: loading 2.5s linear infinite;
}

@keyframes loading {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@-webkit-keyframes loading {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
	}
}

#toast {
	position: fixed;
	z-index: 10001;
	padding: 4px 10px;
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.75);
	color: rgba(255, 255, 255, 0.75);
	font-size: 12px;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	display: none;
}