.against-result-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 40px 14px 28px;
  background-color: #a13b36;
  position: relative;
  .againstor{
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 40px;
    >.pk{
      position: absolute;
      left: 50%;
      top:50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/pk.png");
      width: 70px;
      height: 44px;
    }
    >.user{
      display: flex;
      >.name{
        font-size: 16px;
        color: #ffffff;
        box-sizing: border-box;
        padding-top: 24px;
      }
      >.detail{
        display: flex;
        flex-direction: column;
        align-items: center;
        >.avatar{
          height: 60px;
          width: 60px;
          background-color: #ffe5e5;
          border-radius: 50%;
	        background-size: cover;
	        background-position: 50% 50%;
	        background-repeat: no-repeat;
        }
        >.point{
          font-size: 14px;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 14px;
          >em{
            font-style: normal;
            color: #feca27;
            font-size: 23px;
          }
        }
        >.result{
          font-size: 12px;
          background-color: #600d09;
          border-radius: 16px;
          height: 20px;
          margin-top: 6px;
          line-height: 20px;
          text-align: center;
          color: #ffffff;
          padding: 0 8px;
        }
      }
    }
  }
  .against-result-cover{
    flex: 1;
    background-size: auto 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    margin-bottom: 40px;
    &.success{
      background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/against-success.png");
    }
    &.fail{
      background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/against-fail.png");
    }
  }
  .result-point{
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background-color: #912c25;
    margin: 0 auto 20px;
    width: 68%;
    >.img{
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      width: 24px;
      height:24px;
      background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/point.png");
    }
    >span{
      font-size: 14px;
      text-align: center;
      margin-left: 6px;
      color: #ffffff;
    }
  }
  .result-start{
    background-image: linear-gradient(#ff9532, #ff4b09);
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #ffffff;
    border-radius: 20px;
    font-size: 14px;
    width: 68%;
    margin: 0 auto 20px;
  }
  .check-order{
    text-align: center;
    text-decoration: underline;
    color: #ffffff;
  }
}
