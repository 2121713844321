.against-answer-wrapper {
	flex: 1;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	padding: 20px 14px;
	background-color: #a13b36;
	position: relative;
	background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/against-bottom-bg.png");
	background-repeat: no-repeat;
	background-position: 50% 100%;
	background-size: 100%;
	.userinfo {
		display: flex;
		justify-content: space-between;
		position: relative;
		// margin-bottom: 10%;
		height: 62px;
		> .timer {
			position: absolute;
			left: 50%;
			top: 50%;
			-webkit-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			width: 42px;
			height: 42px;
			border-radius: 50%;
			overflow: hidden;
			//>.bg{
			//  position: absolute;
			//  left: 0;
			//  top: 0;
			//  width: 100%;
			//  height: 100%;
			//  background-color: #0000ff;
			//    z-index: 2;
			//    border-radius: 50%;
			//    overflow: hidden;
			//}
			//>.bg2{
			//  position: absolute;
			//  left: 3px;
			//  right: 3px;
			//  top: 3px;
			//  bottom: 3px;
			//  background-color: #ffffff;
			//    z-index: 3;
			//    border-radius: 50%;
			//    overflow: hidden;
			//    clip: rect(0px,38px,38px,0px);
			//}
			> .time {
				z-index: 3;
				border-radius: 50%;
				position: absolute;
				left: 3px;
				right: 3px;
				top: 3px;
				bottom: 3px;
				background-color: #a13b36;
				font-size: 16px;
				border: solid 3px #ffffff;
				color: #ffffff;;
				display: flex;
				align-items: center;
				box-sizing: border-box;
				justify-content: center;
			}
		}
		.avatar {
			display: flex;
			align-items: center;
			> .img {
				width: 62px;
				height: 62px;
				background-color: rgba(0, 0, 0, 0.45);
				border-radius: 50%;
				background-size: cover;
				background-position: 50% 50%;
				background-repeat: no-repeat;
			}
			> span {
				font-size: 16px;
				color: #ffffff;
			}
		}
	}
	.question-wrapper {
		background-color: #fde3c7;
		border-radius: 8px;
		// margin-bottom: 40px;
		// overflow: auto;
		flex: 1;
		display: flex;
		flex-direction: column;
		margin: 12% 0 6%;
		> .aqheader {
			height: 54px;
			line-height: 54px;
			color: #333333;
			border-bottom: solid 1px #ffffff;
			text-align: center;
			> i {
				font-style: normal;
				color: #a13b36;
			}
		}
		> .aqcontent {
			flex: 1;
			overflow: auto;
			padding: 16px 10px;
			line-height: 1.5;
			max-height: 120px;
			// overflow: auto;
			box-sizing: border-box;
		}
	}
	.question-answer {
		display: flex;
		overflow: hidden;
		height: 240px;
		.user-point {
			width: 14px;
			//height: 224px;
			display: flex;
			flex-direction: column;
			align-items: center;
			> .total {
				width: 10px;
				background-color: #600d09;
				flex: 1;
				border-radius: 8px;
				margin-top: 10px;
				position: relative;
				overflow: hidden;
				> em {
					position: absolute;
					left: 0;
					bottom: 0;
					right: 0;
					background-color: #ffa200;
				}
			}
			> span {
				color: #ffa200;
				font-size: 14px;
			}
		}
		.answer-wrap {
			flex: 1;
			height: 100%;
			margin: 26px 14px 0;
			overflow: auto;
			box-sizing: border-box;
			padding-bottom: 26px;
			//display: flex;
			//justify-content: flex-end;
			//flex-direction: column;
			// padding-bottom: 14px;
			> .item {
				background-color: #ffffff;
				border: solid 1px #dcdcdc;
				border-radius: 4px;
				display: flex;
				align-items: flex-start;
				padding: 10px 40px 10px 10px;
				margin-bottom: 14px;
				box-sizing: border-box;
				line-height: 1.5;
				> label {
					width: 20px;
				}
				> span {
					flex: 1;
				}
				&:last-child {
					margin-bottom: 0;
				}
				&.right {
					position: relative;
					&:after {
						position: absolute;
						right: 20px;
						top: 10px;
						content: '';
						width: 24px;
						height: 18px;
						background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/status-start.png");
						background-position: 100% 50%;
						background-size: 100%;
						background-repeat: no-repeat;
					}
				}
				&.wrong {
					position: relative;
					&:after {
						position: absolute;
						right: 20px;
						top: 10px;
						content: '';
						width: 18px;
						height: 18px;
						background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/status-wait.png");
						background-position: 100% 50%;
						background-size: 100%;
						background-repeat: no-repeat;
					}
				}
				&.selected {
					position: relative;
					background-color: rgba(84, 84, 84, 0.35);
					color: #ffffff;
				}
			}
		}
	}
}


