.page-container {
	-webkit-display: flex;
	display: flex;
	flex-direction: column;
	background-color: #a13b36;

	.fight-status-wrap{
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 99;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(0,0,0,0.75);
		>.fight-status-confirm{
			background-color: #ffffff;
			border-radius: 20px;
			overflow: hidden;
			>.title{
				height: 44px;
				line-height: 44px;
				text-align: center;
				color: #333333;
				font-size: 16px;
				font-weight: 800;
				border-bottom: solid 1px #dcdcdc;
			}
			>.content{
				padding: 28px 20px;
				text-align: justify;
			}
			>.btn{
				border-top: solid 1px #dcdcdc;
				display: flex;
				height: 56px;
				>span{
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;
					&:first-child{
						&:after{
							position: absolute;
							right: 0;
							-webkit-transform: translateX(50%);
							transform: translateX(50%);
							width: 1px;
							height: 45%;
							background-color: #DCDCDC;
							content: '';
						}
					}
					>em{
						display: block;
						width: 18px;
						height: 18px;
						background-size: cover;
						background-position: 50% 50%;
						background-repeat: no-repeat;
						margin-right: 4px;
						&.accept{
							background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/accept.png");
						}
						&.reject{
							background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/reject.png");
						}
					}
				}
			}
		}
	}
}