.topic-answer-result-wrapper {
	flex: 1;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	padding: 20px 14px;
	background-color: #f1f1f1;
	background-repeat: no-repeat;
	background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/result-bg.jpg");
	background-size: cover;
	position: relative;
	>.btn-wrapper{
		position: absolute;
		left:50%;
		top: 50%;
		-webkit-transform: translate(-50%, 0);
		transform: translate(-50%, 0);
		width: 75%;
		>.result{
			margin-bottom: 50px;
			text-align: center;
			width: 100%;
			font-size: 20px;
			color: #d51c1b;
			>span{
				color: #00AAAA;
				font-weight: 600;
				padding: 0 10px;
				font-size: 32px;
				position: relative;
				top: 4px;
			}
		}
		>.item{
			display: block;
			border: solid 1px #f7385c;
			background-color: #f7385c;
			color: #ffffff;
			width: 100%;
			height: 38px;
			line-height: 38px;
			margin-bottom: 20px;
			text-align: center;
			border-radius: 20px;
			text-decoration: none;
			font-size: 16px;
			&:last-child{
				margin-bottom: 0;
			}
			&.blank{
				border: solid 1px #f7385c;
				color: #ffffff;
			}
		}
	}
	//.qr-wrapper {
	//	background-color: #ffffff;
	//	border: solid 1px #dcdcdc;
	//	border-radius: 4px;
	//	padding: 0 14px 20px;
	//	margin-bottom: 58px;
	//	.qrheader {
	//		height: 60px;
	//		display: flex;
	//		align-items: center;
	//		border-bottom: solid 1px #dcdcdc;
	//		> em {
	//			width: 6px;
	//			height: 30px;
	//			background-color: #993331;
	//			border-radius: 4px;
	//			display: block;
	//			margin-right: 10px;
	//		}
	//		> span {
	//
	//		}
	//	}
	//	.userinfo {
	//		display: flex;
	//		justify-content: space-between;
	//		flex: 1;
	//		align-items: center;
	//		color: #ffffff;
	//		box-sizing: border-box;
	//		margin: 14px 0 30px;
	//		> .avatar {
	//			display: flex;
	//			height: 62px;
	//			align-items: center;
	//			> .img {
	//				background-size: cover;
	//				background-position: 50% 50%;
	//				background-repeat: no-repeat;
	//				width: 62px;
	//				height: 62px;
	//				background-image: url("https://qp-competition.oss-cn-shanghai.aliyuncs.com/user.png");
	//			}
	//			> span {
	//				margin-left: 10px;
	//				font-size: 16px;
	//				color: #333333;
	//			}
	//		}
	//		> .point {
	//			color: #333333;
	//			font-size: 16px;
	//			display: flex;
	//			align-items: center;
	//			> span {
	//				font-size: 40px;
	//				color: #10a926;
	//				margin-right: 4px;
	//			}
	//		}
	//	}
	//	> .info {
	//		display: flex;
	//		height: 70px;
	//		align-items: center;
	//		border: solid 1px #dcdcdc;
	//		color: #666666;
	//		font-size: 14px;
	//		> .info-item {
	//			display: flex;
	//			flex: 1;
	//			flex-direction: column;
	//			align-items: center;
	//			position: relative;
	//			> span {
	//				margin-top: 10px;
	//			}
	//			&:after {
	//				position: absolute;
	//				right: 0;
	//				top: 50%;
	//				height: 75%;
	//				width: 1px;
	//				background-color: #dcdcdc;
	//				content: '';
	//				transform: translateY(-50%);
	//			}
	//			&:last-child {
	//				&:after {
	//					display: none;
	//				}
	//			}
	//		}
	//	}
	//}
	//.btn-brand {
	//	border: solid 1px #3a92ff;
	//	background-color: #3a92ff;
	//	color: #ffffff;
	//	width: 100%;
	//	height: 38px;
	//	line-height: 38px;
	//	margin-bottom: 25px;
	//	text-align: center;
	//	border-radius: 20px;
	//	text-decoration: none;
	//}
	//.btn-again {
	//	border: solid 1px #3a92ff;
	//	color: #3a92ff;
	//	width: 100%;
	//	line-height: 38px;
	//	text-align: center;
	//	text-decoration: none;
	//	border-radius: 20px;
	//}
}
